<template>
    <FullTextMap></FullTextMap>
</template>

<script>
    import FullTextMap from "@/components/FullTextMap.vue"
    export default {
        components: {
            FullTextMap
        }
    }
</script>